import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      'description' : 'I created “Keep Distance Ruler,” \nan AR ruler that measures the distance \n(approximately 6 feet) necessary \nfor social distancing. \nJust tap the 3D model above, \nand you can measure the distance \nyou need to keep, wherever you are. \nI hope this will help keep you safe \nwhen you go outside.',
      'device' : 'Compatible Devices: iPhone X and later models \n(latest iOS recommended)'
    },
  },
  ja: {
    translation: {
      'description' : '新型コロナウイルス感染拡大防止対策の\nソーシャルディスタンスに必要な2mを測るAR定規\n「Keep Distance Ruler」を制作しました。\n上記の3Dモデルをタップするだけで\nどこでも2mの距離を計測することができます\n外出の際に、お役立ていただければ幸いです。',
      'device' : '対応デバイス：iPhoneX以降 (最新版iOS推奨)'
    },
  },
};

const detection = {
  // order and from where user language should be detected
  order: ['querystring', 'navigator', 'htmlTag'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },

    detection
  });

export default i18n;
