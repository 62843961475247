import React from 'react';
import './App.scss';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  const isIOS = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent)
  }

  const arImage = () => {
    if (isIOS()) {
      return (
        <a href={`${process.env.PUBLIC_URL}/images/Keepdistance_Ruler.usdz`} rel="ar" className="arImage">
          <img src={`${process.env.PUBLIC_URL}/images/AR_Image720x720.jpg`} />
        </a>
      )
    } else if (i18n.language === 'ja') {
      return (
        <img src={`${process.env.PUBLIC_URL}/images/AR_Image720x720_no_jp.jpg`} className="arImage" />
      )
    } else {
      return (
        <img src={`${process.env.PUBLIC_URL}/images/AR_Image720x720_no_en.jpg`} className="arImage" />
      )
    }
  }

  const renderText = (key:string) => {
    return t(key).split('\n').map(text =>
      <>{text} <br/></>
    )
  }

  return (
    <div className="main">
      <header>
        <ul className="lang">
          <li className={i18n.language === 'ja' ? 'active' : ''}><a href={i18n.language === 'ja' ? '' : '/?lng=ja'} rel='alternate'>JP</a></li>
          <li className={i18n.language !== 'ja' ? 'active' : ''}><a href={i18n.language !== 'ja' ? '' : '/?lng=en'} rel='alternate'>EN</a></li>
        </ul>
      </header>
      <div className="eyecatch">
        <div className="container">
          <h1><span>KEEP DISTANCE RULER</span></h1>
          {arImage()}
        </div>
      </div>
      <p className="description">
        {renderText('description')}
      </p>
      <footer>
        <p>{renderText('device')}</p>
        <div className="staff">
          <p>Created by : Keisuke Terashima</p>
          <p>Web Development : Keita Mori</p>
          <p>English Translation : Mandy Wang</p>
        </div>
        <a href="https://prty.jp/">
          <img src={`${process.env.PUBLIC_URL}/images/PARTY_logo.png`} />
        </a>
      </footer>
    </div>
  );
}

export default withTranslation()(App);
